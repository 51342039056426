export const countries = [
  {
    id: 1,
    sortname: "AF",
    name: "Afghanistan",
    phoneCode: 93,
  },
  {
    id: 2,
    sortname: "AL",
    name: "Albania",
    phoneCode: 355,
  },
  {
    id: 3,
    sortname: "DZ",
    name: "Algeria",
    phoneCode: 213,
  },
  {
    id: 4,
    sortname: "AS",
    name: "American Samoa",
    phoneCode: 1684,
  },
  {
    id: 5,
    sortname: "AD",
    name: "Andorra",
    phoneCode: 376,
  },
  {
    id: 6,
    sortname: "AO",
    name: "Angola",
    phoneCode: 244,
  },
  {
    id: 7,
    sortname: "AI",
    name: "Anguilla",
    phoneCode: 1264,
  },
  {
    id: 8,
    sortname: "AQ",
    name: "Antarctica",
    phoneCode: 0,
  },
  {
    id: 9,
    sortname: "AG",
    name: "Antigua And Barbuda",
    phoneCode: 1268,
  },
  {
    id: 10,
    sortname: "AR",
    name: "Argentina",
    phoneCode: 54,
  },
  {
    id: 11,
    sortname: "AM",
    name: "Armenia",
    phoneCode: 374,
  },
  {
    id: 12,
    sortname: "AW",
    name: "Aruba",
    phoneCode: 297,
  },
  {
    id: 13,
    sortname: "AU",
    name: "Australia",
    phoneCode: 61,
  },
  {
    id: 14,
    sortname: "AT",
    name: "Austria",
    phoneCode: 43,
  },
  {
    id: 15,
    sortname: "AZ",
    name: "Azerbaijan",
    phoneCode: 994,
  },
  {
    id: 16,
    sortname: "BS",
    name: "Bahamas The",
    phoneCode: 1242,
  },
  {
    id: 17,
    sortname: "BH",
    name: "Bahrain",
    phoneCode: 973,
  },
  {
    id: 18,
    sortname: "BD",
    name: "Bangladesh",
    phoneCode: 880,
  },
  {
    id: 19,
    sortname: "BB",
    name: "Barbados",
    phoneCode: 1246,
  },
  {
    id: 20,
    sortname: "BY",
    name: "Belarus",
    phoneCode: 375,
  },
  {
    id: 21,
    sortname: "BE",
    name: "Belgium",
    phoneCode: 32,
  },
  {
    id: 22,
    sortname: "BZ",
    name: "Belize",
    phoneCode: 501,
  },
  {
    id: 23,
    sortname: "BJ",
    name: "Benin",
    phoneCode: 229,
  },
  {
    id: 24,
    sortname: "BM",
    name: "Bermuda",
    phoneCode: 1441,
  },
  {
    id: 25,
    sortname: "BT",
    name: "Bhutan",
    phoneCode: 975,
  },
  {
    id: 26,
    sortname: "BO",
    name: "Bolivia",
    phoneCode: 591,
  },
  {
    id: 27,
    sortname: "BA",
    name: "Bosnia and Herzegovina",
    phoneCode: 387,
  },
  {
    id: 28,
    sortname: "BW",
    name: "Botswana",
    phoneCode: 267,
  },
  {
    id: 29,
    sortname: "BV",
    name: "Bouvet Island",
    phoneCode: 0,
  },
  {
    id: 30,
    sortname: "BR",
    name: "Brazil",
    phoneCode: 55,
  },
  {
    id: 31,
    sortname: "IO",
    name: "British Indian Ocean Territory",
    phoneCode: 246,
  },
  {
    id: 32,
    sortname: "BN",
    name: "Brunei",
    phoneCode: 673,
  },
  {
    id: 33,
    sortname: "BG",
    name: "Bulgaria",
    phoneCode: 359,
  },
  {
    id: 34,
    sortname: "BF",
    name: "Burkina Faso",
    phoneCode: 226,
  },
  {
    id: 35,
    sortname: "BI",
    name: "Burundi",
    phoneCode: 257,
  },
  {
    id: 36,
    sortname: "KH",
    name: "Cambodia",
    phoneCode: 855,
  },
  {
    id: 37,
    sortname: "CM",
    name: "Cameroon",
    phoneCode: 237,
  },
  {
    id: 38,
    sortname: "CA",
    name: "Canada",
    phoneCode: 1,
  },
  {
    id: 39,
    sortname: "CV",
    name: "Cape Verde",
    phoneCode: 238,
  },
  {
    id: 40,
    sortname: "KY",
    name: "Cayman Islands",
    phoneCode: 1345,
  },
  {
    id: 41,
    sortname: "CF",
    name: "Central African Republic",
    phoneCode: 236,
  },
  {
    id: 42,
    sortname: "TD",
    name: "Chad",
    phoneCode: 235,
  },
  {
    id: 43,
    sortname: "CL",
    name: "Chile",
    phoneCode: 56,
  },
  {
    id: 44,
    sortname: "CN",
    name: "China",
    phoneCode: 86,
  },
  {
    id: 45,
    sortname: "CX",
    name: "Christmas Island",
    phoneCode: 61,
  },
  {
    id: 46,
    sortname: "CC",
    name: "Cocos (Keeling) Islands",
    phoneCode: 672,
  },
  {
    id: 47,
    sortname: "CO",
    name: "Colombia",
    phoneCode: 57,
  },
  {
    id: 48,
    sortname: "KM",
    name: "Comoros",
    phoneCode: 269,
  },
  {
    id: 49,
    sortname: "CG",
    name: "Republic Of The Congo",
    phoneCode: 242,
  },
  {
    id: 50,
    sortname: "CD",
    name: "Democratic Republic Of The Congo",
    phoneCode: 242,
  },
  {
    id: 51,
    sortname: "CK",
    name: "Cook Islands",
    phoneCode: 682,
  },
  {
    id: 52,
    sortname: "CR",
    name: "Costa Rica",
    phoneCode: 506,
  },
  {
    id: 53,
    sortname: "CI",
    name: "Cote D''Ivoire (Ivory Coast)",
    phoneCode: 225,
  },
  {
    id: 54,
    sortname: "HR",
    name: "Croatia (Hrvatska)",
    phoneCode: 385,
  },
  {
    id: 55,
    sortname: "CU",
    name: "Cuba",
    phoneCode: 53,
  },
  {
    id: 56,
    sortname: "CY",
    name: "Cyprus",
    phoneCode: 357,
  },
  {
    id: 57,
    sortname: "CZ",
    name: "Czech Republic",
    phoneCode: 420,
  },
  {
    id: 58,
    sortname: "DK",
    name: "Denmark",
    phoneCode: 45,
  },
  {
    id: 59,
    sortname: "DJ",
    name: "Djibouti",
    phoneCode: 253,
  },
  {
    id: 60,
    sortname: "DM",
    name: "Dominica",
    phoneCode: 1767,
  },
  {
    id: 61,
    sortname: "DO",
    name: "Dominican Republic",
    phoneCode: 1809,
  },
  {
    id: 62,
    sortname: "TP",
    name: "East Timor",
    phoneCode: 670,
  },
  {
    id: 63,
    sortname: "EC",
    name: "Ecuador",
    phoneCode: 593,
  },
  {
    id: 64,
    sortname: "EG",
    name: "Egypt",
    phoneCode: 20,
  },
  {
    id: 65,
    sortname: "SV",
    name: "El Salvador",
    phoneCode: 503,
  },
  {
    id: 66,
    sortname: "GQ",
    name: "Equatorial Guinea",
    phoneCode: 240,
  },
  {
    id: 67,
    sortname: "ER",
    name: "Eritrea",
    phoneCode: 291,
  },
  {
    id: 68,
    sortname: "EE",
    name: "Estonia",
    phoneCode: 372,
  },
  {
    id: 69,
    sortname: "ET",
    name: "Ethiopia",
    phoneCode: 251,
  },
  {
    id: 70,
    sortname: "XA",
    name: "External Territories of Australia",
    phoneCode: 61,
  },
  {
    id: 71,
    sortname: "FK",
    name: "Falkland Islands",
    phoneCode: 500,
  },
  {
    id: 72,
    sortname: "FO",
    name: "Faroe Islands",
    phoneCode: 298,
  },
  {
    id: 73,
    sortname: "FJ",
    name: "Fiji Islands",
    phoneCode: 679,
  },
  {
    id: 74,
    sortname: "FI",
    name: "Finland",
    phoneCode: 358,
  },
  {
    id: 75,
    sortname: "FR",
    name: "France",
    phoneCode: 33,
  },
  {
    id: 76,
    sortname: "GF",
    name: "French Guiana",
    phoneCode: 594,
  },
  {
    id: 77,
    sortname: "PF",
    name: "French Polynesia",
    phoneCode: 689,
  },
  {
    id: 78,
    sortname: "TF",
    name: "French Southern Territories",
    phoneCode: 0,
  },
  {
    id: 79,
    sortname: "GA",
    name: "Gabon",
    phoneCode: 241,
  },
  {
    id: 80,
    sortname: "GM",
    name: "Gambia The",
    phoneCode: 220,
  },
  {
    id: 81,
    sortname: "GE",
    name: "Georgia",
    phoneCode: 995,
  },
  {
    id: 82,
    sortname: "DE",
    name: "Germany",
    phoneCode: 49,
  },
  {
    id: 83,
    sortname: "GH",
    name: "Ghana",
    phoneCode: 233,
  },
  {
    id: 84,
    sortname: "GI",
    name: "Gibraltar",
    phoneCode: 350,
  },
  {
    id: 85,
    sortname: "GR",
    name: "Greece",
    phoneCode: 30,
  },
  {
    id: 86,
    sortname: "GL",
    name: "Greenland",
    phoneCode: 299,
  },
  {
    id: 87,
    sortname: "GD",
    name: "Grenada",
    phoneCode: 1473,
  },
  {
    id: 88,
    sortname: "GP",
    name: "Guadeloupe",
    phoneCode: 590,
  },
  {
    id: 89,
    sortname: "GU",
    name: "Guam",
    phoneCode: 1671,
  },
  {
    id: 90,
    sortname: "GT",
    name: "Guatemala",
    phoneCode: 502,
  },
  {
    id: 91,
    sortname: "XU",
    name: "Guernsey and Alderney",
    phoneCode: 44,
  },
  {
    id: 92,
    sortname: "GN",
    name: "Guinea",
    phoneCode: 224,
  },
  {
    id: 93,
    sortname: "GW",
    name: "Guinea-Bissau",
    phoneCode: 245,
  },
  {
    id: 94,
    sortname: "GY",
    name: "Guyana",
    phoneCode: 592,
  },
  {
    id: 95,
    sortname: "HT",
    name: "Haiti",
    phoneCode: 509,
  },
  {
    id: 96,
    sortname: "HM",
    name: "Heard and McDonald Islands",
    phoneCode: 0,
  },
  {
    id: 97,
    sortname: "HN",
    name: "Honduras",
    phoneCode: 504,
  },
  {
    id: 98,
    sortname: "HK",
    name: "Hong Kong S.A.R.",
    phoneCode: 852,
  },
  {
    id: 99,
    sortname: "HU",
    name: "Hungary",
    phoneCode: 36,
  },
  {
    id: 100,
    sortname: "IS",
    name: "Iceland",
    phoneCode: 354,
  },
  {
    id: 101,
    sortname: "IN",
    name: "India",
    phoneCode: 91,
  },
  {
    id: 102,
    sortname: "ID",
    name: "Indonesia",
    phoneCode: 62,
  },
  {
    id: 103,
    sortname: "IR",
    name: "Iran",
    phoneCode: 98,
  },
  {
    id: 104,
    sortname: "IQ",
    name: "Iraq",
    phoneCode: 964,
  },
  {
    id: 105,
    sortname: "IE",
    name: "Ireland",
    phoneCode: 353,
  },
  {
    id: 106,
    sortname: "IL",
    name: "Israel",
    phoneCode: 972,
  },
  {
    id: 107,
    sortname: "IT",
    name: "Italy",
    phoneCode: 39,
  },
  {
    id: 108,
    sortname: "JM",
    name: "Jamaica",
    phoneCode: 1876,
  },
  {
    id: 109,
    sortname: "JP",
    name: "Japan",
    phoneCode: 81,
  },
  {
    id: 110,
    sortname: "XJ",
    name: "Jersey",
    phoneCode: 44,
  },
  {
    id: 111,
    sortname: "JO",
    name: "Jordan",
    phoneCode: 962,
  },
  {
    id: 112,
    sortname: "KZ",
    name: "Kazakhstan",
    phoneCode: 7,
  },
  {
    id: 113,
    sortname: "KE",
    name: "Kenya",
    phoneCode: 254,
  },
  {
    id: 114,
    sortname: "KI",
    name: "Kiribati",
    phoneCode: 686,
  },
  {
    id: 115,
    sortname: "KP",
    name: "Korea North",
    phoneCode: 850,
  },
  {
    id: 116,
    sortname: "KR",
    name: "Korea South",
    phoneCode: 82,
  },
  {
    id: 117,
    sortname: "KW",
    name: "Kuwait",
    phoneCode: 965,
  },
  {
    id: 118,
    sortname: "KG",
    name: "Kyrgyzstan",
    phoneCode: 996,
  },
  {
    id: 119,
    sortname: "LA",
    name: "Laos",
    phoneCode: 856,
  },
  {
    id: 120,
    sortname: "LV",
    name: "Latvia",
    phoneCode: 371,
  },
  {
    id: 121,
    sortname: "LB",
    name: "Lebanon",
    phoneCode: 961,
  },
  {
    id: 122,
    sortname: "LS",
    name: "Lesotho",
    phoneCode: 266,
  },
  {
    id: 123,
    sortname: "LR",
    name: "Liberia",
    phoneCode: 231,
  },
  {
    id: 124,
    sortname: "LY",
    name: "Libya",
    phoneCode: 218,
  },
  {
    id: 125,
    sortname: "LI",
    name: "Liechtenstein",
    phoneCode: 423,
  },
  {
    id: 126,
    sortname: "LT",
    name: "Lithuania",
    phoneCode: 370,
  },
  {
    id: 127,
    sortname: "LU",
    name: "Luxembourg",
    phoneCode: 352,
  },
  {
    id: 128,
    sortname: "MO",
    name: "Macau S.A.R.",
    phoneCode: 853,
  },
  {
    id: 129,
    sortname: "MK",
    name: "Macedonia",
    phoneCode: 389,
  },
  {
    id: 130,
    sortname: "MG",
    name: "Madagascar",
    phoneCode: 261,
  },
  {
    id: 131,
    sortname: "MW",
    name: "Malawi",
    phoneCode: 265,
  },
  {
    id: 132,
    sortname: "MY",
    name: "Malaysia",
    phoneCode: 60,
  },
  {
    id: 133,
    sortname: "MV",
    name: "Maldives",
    phoneCode: 960,
  },
  {
    id: 134,
    sortname: "ML",
    name: "Mali",
    phoneCode: 223,
  },
  {
    id: 135,
    sortname: "MT",
    name: "Malta",
    phoneCode: 356,
  },
  {
    id: 136,
    sortname: "XM",
    name: "Man (Isle of)",
    phoneCode: 44,
  },
  {
    id: 137,
    sortname: "MH",
    name: "Marshall Islands",
    phoneCode: 692,
  },
  {
    id: 138,
    sortname: "MQ",
    name: "Martinique",
    phoneCode: 596,
  },
  {
    id: 139,
    sortname: "MR",
    name: "Mauritania",
    phoneCode: 222,
  },
  {
    id: 140,
    sortname: "MU",
    name: "Mauritius",
    phoneCode: 230,
  },
  {
    id: 141,
    sortname: "YT",
    name: "Mayotte",
    phoneCode: 269,
  },
  {
    id: 142,
    sortname: "MX",
    name: "Mexico",
    phoneCode: 52,
  },
  {
    id: 143,
    sortname: "FM",
    name: "Micronesia",
    phoneCode: 691,
  },
  {
    id: 144,
    sortname: "MD",
    name: "Moldova",
    phoneCode: 373,
  },
  {
    id: 145,
    sortname: "MC",
    name: "Monaco",
    phoneCode: 377,
  },
  {
    id: 146,
    sortname: "MN",
    name: "Mongolia",
    phoneCode: 976,
  },
  {
    id: 147,
    sortname: "MS",
    name: "Montserrat",
    phoneCode: 1664,
  },
  {
    id: 148,
    sortname: "MA",
    name: "Morocco",
    phoneCode: 212,
  },
  {
    id: 149,
    sortname: "MZ",
    name: "Mozambique",
    phoneCode: 258,
  },
  {
    id: 150,
    sortname: "MM",
    name: "Myanmar",
    phoneCode: 95,
  },
  {
    id: 151,
    sortname: "NA",
    name: "Namibia",
    phoneCode: 264,
  },
  {
    id: 152,
    sortname: "NR",
    name: "Nauru",
    phoneCode: 674,
  },
  {
    id: 153,
    sortname: "NP",
    name: "Nepal",
    phoneCode: 977,
  },
  {
    id: 154,
    sortname: "AN",
    name: "Netherlands Antilles",
    phoneCode: 599,
  },
  {
    id: 155,
    sortname: "NL",
    name: "Netherlands The",
    phoneCode: 31,
  },
  {
    id: 156,
    sortname: "NC",
    name: "New Caledonia",
    phoneCode: 687,
  },
  {
    id: 157,
    sortname: "NZ",
    name: "New Zealand",
    phoneCode: 64,
  },
  {
    id: 158,
    sortname: "NI",
    name: "Nicaragua",
    phoneCode: 505,
  },
  {
    id: 159,
    sortname: "NE",
    name: "Niger",
    phoneCode: 227,
  },
  {
    id: 160,
    sortname: "NG",
    name: "Nigeria",
    phoneCode: 234,
  },
  {
    id: 161,
    sortname: "NU",
    name: "Niue",
    phoneCode: 683,
  },
  {
    id: 162,
    sortname: "NF",
    name: "Norfolk Island",
    phoneCode: 672,
  },
  {
    id: 163,
    sortname: "MP",
    name: "Northern Mariana Islands",
    phoneCode: 1670,
  },
  {
    id: 164,
    sortname: "NO",
    name: "Norway",
    phoneCode: 47,
  },
  {
    id: 165,
    sortname: "OM",
    name: "Oman",
    phoneCode: 968,
  },
  {
    id: 166,
    sortname: "PK",
    name: "Pakistan",
    phoneCode: 92,
  },
  {
    id: 167,
    sortname: "PW",
    name: "Palau",
    phoneCode: 680,
  },
  {
    id: 168,
    sortname: "PS",
    name: "Palestinian Territory Occupied",
    phoneCode: 970,
  },
  {
    id: 169,
    sortname: "PA",
    name: "Panama",
    phoneCode: 507,
  },
  {
    id: 170,
    sortname: "PG",
    name: "Papua new Guinea",
    phoneCode: 675,
  },
  {
    id: 171,
    sortname: "PY",
    name: "Paraguay",
    phoneCode: 595,
  },
  {
    id: 172,
    sortname: "PE",
    name: "Peru",
    phoneCode: 51,
  },
  {
    id: 173,
    sortname: "PH",
    name: "Philippines",
    phoneCode: 63,
  },
  {
    id: 174,
    sortname: "PN",
    name: "Pitcairn Island",
    phoneCode: 0,
  },
  {
    id: 175,
    sortname: "PL",
    name: "Poland",
    phoneCode: 48,
  },
  {
    id: 176,
    sortname: "PT",
    name: "Portugal",
    phoneCode: 351,
  },
  {
    id: 177,
    sortname: "PR",
    name: "Puerto Rico",
    phoneCode: 1787,
  },
  {
    id: 178,
    sortname: "QA",
    name: "Qatar",
    phoneCode: 974,
  },
  {
    id: 179,
    sortname: "RE",
    name: "Reunion",
    phoneCode: 262,
  },
  {
    id: 180,
    sortname: "RO",
    name: "Romania",
    phoneCode: 40,
  },
  {
    id: 181,
    sortname: "RU",
    name: "Russia",
    phoneCode: 70,
  },
  {
    id: 182,
    sortname: "RW",
    name: "Rwanda",
    phoneCode: 250,
  },
  {
    id: 183,
    sortname: "SH",
    name: "Saint Helena",
    phoneCode: 290,
  },
  {
    id: 184,
    sortname: "KN",
    name: "Saint Kitts And Nevis",
    phoneCode: 1869,
  },
  {
    id: 185,
    sortname: "LC",
    name: "Saint Lucia",
    phoneCode: 1758,
  },
  {
    id: 186,
    sortname: "PM",
    name: "Saint Pierre and Miquelon",
    phoneCode: 508,
  },
  {
    id: 187,
    sortname: "VC",
    name: "Saint Vincent And The Grenadines",
    phoneCode: 1784,
  },
  {
    id: 188,
    sortname: "WS",
    name: "Samoa",
    phoneCode: 684,
  },
  {
    id: 189,
    sortname: "SM",
    name: "San Marino",
    phoneCode: 378,
  },
  {
    id: 190,
    sortname: "ST",
    name: "Sao Tome and Principe",
    phoneCode: 239,
  },
  {
    id: 191,
    sortname: "SA",
    name: "Saudi Arabia",
    phoneCode: 966,
  },
  {
    id: 192,
    sortname: "SN",
    name: "Senegal",
    phoneCode: 221,
  },
  {
    id: 193,
    sortname: "RS",
    name: "Serbia",
    phoneCode: 381,
  },
  {
    id: 194,
    sortname: "SC",
    name: "Seychelles",
    phoneCode: 248,
  },
  {
    id: 195,
    sortname: "SL",
    name: "Sierra Leone",
    phoneCode: 232,
  },
  {
    id: 196,
    sortname: "SG",
    name: "Singapore",
    phoneCode: 65,
  },
  {
    id: 197,
    sortname: "SK",
    name: "Slovakia",
    phoneCode: 421,
  },
  {
    id: 198,
    sortname: "SI",
    name: "Slovenia",
    phoneCode: 386,
  },
  {
    id: 199,
    sortname: "XG",
    name: "Smaller Territories of the UK",
    phoneCode: 44,
  },
  {
    id: 200,
    sortname: "SB",
    name: "Solomon Islands",
    phoneCode: 677,
  },
  {
    id: 201,
    sortname: "SO",
    name: "Somalia",
    phoneCode: 252,
  },
  {
    id: 202,
    sortname: "ZA",
    name: "South Africa",
    phoneCode: 27,
  },
  {
    id: 203,
    sortname: "GS",
    name: "South Georgia",
    phoneCode: 0,
  },
  {
    id: 204,
    sortname: "SS",
    name: "South Sudan",
    phoneCode: 211,
  },
  {
    id: 205,
    sortname: "ES",
    name: "Spain",
    phoneCode: 34,
  },
  {
    id: 206,
    sortname: "LK",
    name: "Sri Lanka",
    phoneCode: 94,
  },
  {
    id: 207,
    sortname: "SD",
    name: "Sudan",
    phoneCode: 249,
  },
  {
    id: 208,
    sortname: "SR",
    name: "Suriname",
    phoneCode: 597,
  },
  {
    id: 209,
    sortname: "SJ",
    name: "Svalbard And Jan Mayen Islands",
    phoneCode: 47,
  },
  {
    id: 210,
    sortname: "SZ",
    name: "Swaziland",
    phoneCode: 268,
  },
  {
    id: 211,
    sortname: "SE",
    name: "Sweden",
    phoneCode: 46,
  },
  {
    id: 212,
    sortname: "CH",
    name: "Switzerland",
    phoneCode: 41,
  },
  {
    id: 213,
    sortname: "SY",
    name: "Syria",
    phoneCode: 963,
  },
  {
    id: 214,
    sortname: "TW",
    name: "Taiwan",
    phoneCode: 886,
  },
  {
    id: 215,
    sortname: "TJ",
    name: "Tajikistan",
    phoneCode: 992,
  },
  {
    id: 216,
    sortname: "TZ",
    name: "Tanzania",
    phoneCode: 255,
  },
  {
    id: 217,
    sortname: "TH",
    name: "Thailand",
    phoneCode: 66,
  },
  {
    id: 218,
    sortname: "TG",
    name: "Togo",
    phoneCode: 228,
  },
  {
    id: 219,
    sortname: "TK",
    name: "Tokelau",
    phoneCode: 690,
  },
  {
    id: 220,
    sortname: "TO",
    name: "Tonga",
    phoneCode: 676,
  },
  {
    id: 221,
    sortname: "TT",
    name: "Trinidad And Tobago",
    phoneCode: 1868,
  },
  {
    id: 222,
    sortname: "TN",
    name: "Tunisia",
    phoneCode: 216,
  },
  {
    id: 223,
    sortname: "TR",
    name: "Turkey",
    phoneCode: 90,
  },
  {
    id: 224,
    sortname: "TM",
    name: "Turkmenistan",
    phoneCode: 7370,
  },
  {
    id: 225,
    sortname: "TC",
    name: "Turks And Caicos Islands",
    phoneCode: 1649,
  },
  {
    id: 226,
    sortname: "TV",
    name: "Tuvalu",
    phoneCode: 688,
  },
  {
    id: 227,
    sortname: "UG",
    name: "Uganda",
    phoneCode: 256,
  },
  {
    id: 228,
    sortname: "UA",
    name: "Ukraine",
    phoneCode: 380,
  },
  {
    id: 229,
    sortname: "AE",
    name: "United Arab Emirates",
    phoneCode: 971,
  },
  {
    id: 230,
    sortname: "GB",
    name: "United Kingdom",
    phoneCode: 44,
  },
  {
    id: 231,
    sortname: "US",
    name: "United States",
    phoneCode: 1,
  },
  {
    id: 232,
    sortname: "UM",
    name: "United States Minor Outlying Islands",
    phoneCode: 1,
  },
  {
    id: 233,
    sortname: "UY",
    name: "Uruguay",
    phoneCode: 598,
  },
  {
    id: 234,
    sortname: "UZ",
    name: "Uzbekistan",
    phoneCode: 998,
  },
  {
    id: 235,
    sortname: "VU",
    name: "Vanuatu",
    phoneCode: 678,
  },
  {
    id: 236,
    sortname: "VA",
    name: "Vatican City State (Holy See)",
    phoneCode: 39,
  },
  {
    id: 237,
    sortname: "VE",
    name: "Venezuela",
    phoneCode: 58,
  },
  {
    id: 238,
    sortname: "VN",
    name: "Vietnam",
    phoneCode: 84,
  },
  {
    id: 239,
    sortname: "VG",
    name: "Virgin Islands (British)",
    phoneCode: 1284,
  },
  {
    id: 240,
    sortname: "VI",
    name: "Virgin Islands (US)",
    phoneCode: 1340,
  },
  {
    id: 241,
    sortname: "WF",
    name: "Wallis And Futuna Islands",
    phoneCode: 681,
  },
  {
    id: 242,
    sortname: "EH",
    name: "Western Sahara",
    phoneCode: 212,
  },
  {
    id: 243,
    sortname: "YE",
    name: "Yemen",
    phoneCode: 967,
  },
  {
    id: 244,
    sortname: "YU",
    name: "Yugoslavia",
    phoneCode: 38,
  },
  {
    id: 245,
    sortname: "ZM",
    name: "Zambia",
    phoneCode: 260,
  },
  {
    id: 246,
    sortname: "ZW",
    name: "Zimbabwe",
    phoneCode: 26,
  },
];
